import React from "react";
import Countdown from "./Countdown";

function NavBar() {
    const targetDate = "2024-07-08T00:00:00";

    return (
        <div id="nav-bar">
            <ul className="nav-left">
                <li>
                    <a href="/">nochillio</a>
                </li>
            </ul>
            {/* <div className="nav-center">
                <Countdown targetDate={targetDate} />
            </div> */}
            <ul className="nav-menu">
                <li>
                    <a
                        href="https://joepegs.com/collections/avalanche/0x204b3ee3f9bdcde258ba3f74de76ea8eedf0a36a"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        BUY NOCHILLIO
                    </a>
                </li>
                <li>
                    <a
                        href="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xAcFb898Cff266E53278cC0124fC2C7C94C8cB9a5"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        BUY NOCHILL
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default NavBar;
