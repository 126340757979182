import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../LandingPage.css";
import LazyLoadingImage from "./LazyLoadingImage";

const imagePaths = [
    "./images/assets/worldorder.png",
    "./images/nochillio_final.png",
    "./images/triangle.png",
    "./images/nochillio_monitor.png",
    "./images/nochill_monitor.png"
];

const preloadedImages = {};
imagePaths.forEach((src) => {
    const img = new Image();
    img.src = src;
    preloadedImages[src] = img;
});

const LandingPage = () => {
    const navigate = useNavigate();
    const menuItems = [
        { label: "PIMP MY WIDE", path: "/pimpmywide" },
        { label: "NOCHILLIO", path: "https://nochill.io" },
        { label: "NOCHILL", path: "https://nochill.com" },
        { label: "TELEGRAM", path: "https://t.me/nochillavax" },
    ];

    const whereToBuyLinks = [
        { label: "TraderJoe", url: "https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xAcFb898Cff266E53278cC0124fC2C7C94C8cB9a5" },
        { label: "OKX", url: "https://www.okx.com/web3/dex-swap?inputChain=43114&inputCurrency=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&outputChain=43114&outputCurrency=0xacfb898cff266e53278cc0124fc2c7c94c8cb9a5" },
        { label: "Poloniex", url: "https://poloniex.com/trade/NOCHILL_USDT?type=spot" },
        { label: "Coinstore", url: "https://www.coinstore.com/#/spot/NOCHILLUSDT" },
        { label: "Coinex", url: "https://www.coinex.com/en/exchange/nochill-usdt" },
        { label: "Bitrue", url: "https://www.bitrue.com/trade/nochill_usdt" },
        { label: "Dexalot", url: "https://app.dexalot.com/trade/NOCHILL-AVAX" }
    ];

    const nochillioLinks = [
        { label: "Twitter", url: "https://twitter.com/NOCHILLIONAIRE" },
        { label: "Arena", url: "https://arena.social/NOCHILLIONAIRE" },
        { label: "Joepegs", url: "https://joepegs.com/collections/avalanche/0x204b3ee3f9bdcde258ba3f74de76ea8eedf0a36a" }
    ];

    const nochillLinks = [
        { label: "Where to Buy" },
        { label: "Chart", url: "https://dexscreener.com/avalanche/0x2d38bde22e044ea59688ca7cdd4f0b5307cc519a"},
        { label: "Merch", url: "https://www.cryptolids.com/nochill"},
        { label: "Twitter", url: "https://twitter.com/nochillavax" },
        { label: "Arena", url: "https://arena.social/nochillavax" }
    ];

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLandingPageModal, setIsLandingPageModal] = useState(false);
    const [landingPageModalContent, setLandingPageModalContent] = useState(null);
    const [isSubListOpen, setIsSubListOpen] = useState(false);
    const [backgroundClassName, setBackgroundClassName] = useState("");
    const [modalSelectedIndex, setModalSelectedIndex] = useState(0);
    const [subListIndex, setSubListIndex] = useState(0);
    const [isBlinking, setIsBlinking] = useState(true);

    const subListRef = useRef(null);
    const blinkTimeout = useRef(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!isLandingPageModal) {
                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    clearTimeout(blinkTimeout.current);
                    setIsBlinking(false);

                    blinkTimeout.current = setTimeout(() => {
                        setIsBlinking(true);
                    }, 10);

                    setSelectedIndex((prevIndex) => {
                        if (e.key === "ArrowDown") {
                            return (prevIndex + 1) % menuItems.length;
                        }
                        return (prevIndex - 1 + menuItems.length) % menuItems.length;
                    });
                } else if (e.key === "Enter") {
                    handleMenuSelect(menuItems[selectedIndex].label, menuItems[selectedIndex].path);
                }
            } else {
                let links = landingPageModalContent === "NOCHILLIO" ? nochillioLinks : nochillLinks;

                if (landingPageModalContent === "NOCHILL" && isSubListOpen) {
                    links = whereToBuyLinks;
                }

                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    clearTimeout(blinkTimeout.current);
                    setIsBlinking(false);

                    blinkTimeout.current = setTimeout(() => {
                        setIsBlinking(true);
                    }, 300);

                    if (isSubListOpen) {
                        setSubListIndex((prevIndex) => {
                            if (e.key === "ArrowDown") {
                                return (prevIndex + 1) % whereToBuyLinks.length;
                            }
                            return (prevIndex - 1 + whereToBuyLinks.length) % whereToBuyLinks.length;
                        });
                    } else {
                        setModalSelectedIndex((prevIndex) => {
                            if (e.key === "ArrowDown") {
                                return (prevIndex + 1) % links.length;
                            }
                            return (prevIndex - 1 + links.length) % links.length;
                        });
                    }
                } else if (e.key === "ArrowLeft" && isSubListOpen) {
                    setIsSubListOpen(false);
                    setModalSelectedIndex(0);
                } else if (e.key === "Enter") {
                    if (landingPageModalContent === "NOCHILL" && modalSelectedIndex === 0) {
                        // Adjusted to open sublist when "Where to Buy" is selected
                        setIsSubListOpen(!isSubListOpen);
                        setSubListIndex(0);
                    } else {
                        const selectedLink = isSubListOpen ? whereToBuyLinks[subListIndex] : links[modalSelectedIndex];
                        if (selectedLink.url) {
                            window.open(selectedLink.url, "_blank");
                        }
                    }
                } else if (e.key === "Escape") {
                    closeLandingPageModal();
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            clearTimeout(blinkTimeout.current);
        };
    }, [selectedIndex, menuItems, isLandingPageModal, landingPageModalContent, modalSelectedIndex, subListIndex, isSubListOpen]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsBlinking((prev) => !prev);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isSubListOpen && subListRef.current) {
            const sublistElement = subListRef.current;
            const selectedElement = sublistElement.children[subListIndex];

            selectedElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [subListIndex, isSubListOpen]);

    const handleMenuSelect = (label, path) => {
        if (label === "NOCHILLIO" || label === "NOCHILL") {
            setLandingPageModalContent(label);
            setIsLandingPageModal(true);
            setBackgroundClassName(label === "NOCHILLIO" ? "nochillio-monitor" : "nochill-monitor");
            setModalSelectedIndex(0);
            setSubListIndex(0);
        } else if (label === "TELEGRAM") {
            window.open(path, "_blank");
        } else {
            navigate(path);
        }
    };

    const handleClick = (label, path) => {
        handleMenuSelect(label, path);
    };

    const closeLandingPageModal = () => {
        setIsLandingPageModal(false);
        setLandingPageModalContent(null);
        setIsSubListOpen(false);
    };

    const handleCopyAddress = () => {
        navigator.clipboard.writeText("0xacfb898cff266e53278cc0124fc2c7c94c8cb9a5").then(() => {
            alert("Contract address copied to clipboard!");
        });
    };

    return (
        <div className="landing-container">
            {isLandingPageModal && <div className="blur-overlay" />}
            <div className="logo-container">
                <LazyLoadingImage
                    src="./images/assets/worldorder.png"
                    alt="Nochillio World Order Logo"
                    className="logo-image"
                />
            </div>
            <div className="content-wrapper">
                <div className="person-container">
                    <LazyLoadingImage src="./images/nochillio_final.png" alt="Person" />
                </div>
                <div className="menu-container">
                    {menuItems.map((item, index) => (
                        <div
                            key={item.label}
                            className={`menu-item ${selectedIndex === index && !isLandingPageModal ? "selected" : ""}`}
                            onClick={() => handleClick(item.label, item.path)}
                        >
                            {selectedIndex === index && !isLandingPageModal && (
                                <LazyLoadingImage src="./images/triangle.png" alt="Cursor" className={`cursor ${isBlinking ? "blink" : ""}`} />
                            )}
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
            {/* Contract Address below the content-wrapper */}
            <div className="contract-address" onClick={handleCopyAddress}>
                CA: 0xacfb898cff266e53278cc0124fc2c7c94c8cb9a5
            </div>
    
            {isLandingPageModal && (
                <div className="landing-page-modal-overlay" onClick={closeLandingPageModal}>
                    <div
                        className={`landing-page-modal-content ${backgroundClassName}`}
                        style={{
                            backgroundImage: `url(${
                                backgroundClassName === "nochillio-monitor"
                                    ? "./images/nochillio_monitor.png"
                                    : "./images/nochill_monitor.png"
                            })`
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button className="landing-page-modal-close-button" onClick={closeLandingPageModal}>
                            X
                        </button>
                        {landingPageModalContent === "NOCHILLIO" && (
                            <div className="landing-page-modal-text">
                                <p className="landing-page-modal-header">NOCHILLIO</p>
                                <ul>
                                    {nochillioLinks.map((link, index) => (
                                        <li
                                            key={link.label}
                                            className={modalSelectedIndex === index ? "selected" : ""}
                                        >
                                            {modalSelectedIndex === index && (
                                                <LazyLoadingImage src="./images/triangle.png" alt="Cursor" className={`cursor-small ${isBlinking ? "blink" : ""}`} />
                                            )}
                                            <a
                                                href={link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {link.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {landingPageModalContent === "NOCHILL" && (
                            <div className="landing-page-modal-text">
                                <p className="landing-page-modal-header">NOCHILL&nbsp;&nbsp;</p>
                                <ul>
                                    {nochillLinks.map((link, index) => (
                                        <React.Fragment key={link.label}>
                                            <li className={modalSelectedIndex === index ? "selected" : ""}>
                                                {modalSelectedIndex === index && !(isSubListOpen && index === 0) && (
                                                    <LazyLoadingImage src="./images/triangle.png" alt="Cursor" className={`cursor-small ${isBlinking ? "blink" : ""}`} />
                                                )}
                                                {link.url ? (
                                                    <a
                                                        href={link.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {link.label}
                                                    </a>
                                                ) : (
                                                    <span
                                                        onClick={() => setIsSubListOpen(!isSubListOpen)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {link.label}
                                                    </span>
                                                )}
                                            </li>
                                            {isSubListOpen && index === 0 && (
                                                <ul className="sub-list" ref={subListRef}>
                                                    {whereToBuyLinks.map((subLink, subIndex) => (
                                                        <li
                                                            key={subLink.label}
                                                            className={subListIndex === subIndex ? "selected" : ""}
                                                        >
                                                            {subListIndex === subIndex && (
                                                                <LazyLoadingImage src="./images/triangle.png" alt="Cursor" className={`cursor-small ${isBlinking ? "blink" : ""}`} />
                                                            )}
                                                            <a
                                                                href={subLink.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {subLink.label}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LandingPage;
